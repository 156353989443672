import { forwardRef } from 'react'
import {
  Typography,
  Button,
  TextField,
  Slide,
  useMediaQuery,
  Dialog,
} from '@mui/material'
import { useForm, useWatch } from 'react-hook-form'
import { useStyles } from './CaravanasNoOficiales.styles'
import { useDispatch } from 'react-redux'
import { setSnackbar } from 'stores/snackbarStore'
import { useNavigate } from 'react-router-dom'
import { numberWithDots } from 'utils/formatNumber'

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

function CaravanasNoOficiales({ open, onClose, onSave, numberTo, packQuantity }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const desktop = useMediaQuery((theme) => theme.breakpoints.up('sm'))
  const navigate = useNavigate()

  const textFieldStyles = {
    fontSize: 12,
    borderRadius: 6,
  }

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    watch
  } = useForm({
    defaultValues: {
      numeracionDesde: '1',
      series: '1',
      numeracionHasta: '',
      observaciones: '',
      cantidad: '',
    },
  })

  const numeracionDesde = useWatch({
    control,
    name: 'numeracionDesde',
  })

  const preventEKey = (e) => e.code === 'KeyE' && e.preventDefault()

  const addQuantityAndSubmit = (data) => {
    if (
      Number(data.numeracionDesde) < 1 ||
      !data.numeracionDesde ||
      isNaN(data.numeracionDesde)
    ) {
      dispatch(setSnackbar(true, 'error', 'El número desde debe ser mayor a 1'))
      return
    }
    const numeroDesdeValido = (Number(data.numeracionDesde) - 1) % packQuantity === 0
    if (!numeroDesdeValido) {
      dispatch(
        setSnackbar(
          true,
          'error',
          `Ingrese un número desde válido. (Ej: 1, ${[1,2,3,4].map((x) => (packQuantity * x) + 1).join(', ')})`
        )
      )
      return
    }
    if(numberTo < 1 || numberTo * series < 1) {
      return dispatch(
        setSnackbar(
          true,
          'error',
          'La cantidad no puede ser menor a 25'
        )
      )
    }
    const newData = {
      ...data,
      numeracionDesde: parseInt(numeracionDesde),
      numeracionHasta: Number(numeracionDesde) + numberTo - 1,
      cantidad: numberTo,
    }
    onSave(newData)
    navigate('/carrito')
    onClose()
  }

  const series = watch('series')

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      fullScreen={!desktop}
      onClose={onClose}
      maxWidth={'md'}
    >
      <div className={classes.container}>
        <div className={classes.titleAndFormContainer}>
          <Typography className={classes.title}>
            Pedido de caravanas no oficiales
          </Typography>

          <form style={{ width: '100%', marginBottom: '40px' }}>
            <div className={classes.formRow}>
              <div className={classes.halfRow}>
                <Typography className={classes.tagName}>
                  Numero desde
                </Typography>
                <TextField
                  onKeyDown={(e) => preventEKey(e)}
                  onPaste={(e) => {
                    isNaN(e.clipboardData.getData('text')) && e.preventDefault()
                  }}
                  InputProps={{
                    style: textFieldStyles,
                    inputProps: { min: '1' },
                  }}
                  variant='outlined'
                  fullWidth
                  required
                  id='numeracionDesde'
                  name='numeracionDesde'
                  type='number'
                  min='1'
                  {...register('numeracionDesde', { required: true })}
                  error={!!errors.numeracionDesde}
                />
                <Typography
                  variant='inherit'
                  color='red'
                  sx={{ paddingTop: 1 }}
                >
                  {errors.numeracionDesde?.type === 'required' &&
                    'El "Numero desde" es obligatorio'}
                </Typography>
              </div>
              <div className={classes.halfRow}>
                <Typography className={classes.tagName}>
                  Numero hasta
                </Typography>
                <TextField
                  onKeyDown={(e) => preventEKey(e)}
                  onPaste={(e) => {
                    isNaN(e.clipboardData.getData('text')) && e.preventDefault()
                  }}
                  InputProps={{
                    style: {
                      ...textFieldStyles,
                      backgroundColor: 'whitesmoke',
                    },
                    value: Number(numeracionDesde) + numberTo - 1,
                  }}
                  title='Este campo se rellena solo cuando completes el campo cantidad'
                  variant='outlined'
                  fullWidth
                  disabled
                  id='numeracionHasta'
                  name='numeracionHasta'
                  type='number'
                />
                <Typography
                  variant='inherit'
                  color='red'
                  sx={{ paddingTop: 1 }}
                >
                  {errors.numeracionHasta?.type === 'required' &&
                    'El "Numero hasta" es obligatorio'}
                </Typography>
              </div>
            </div>

            <div className={classes.formRow}>
              <div className={classes.halfRow}>
                <Typography className={classes.tagName}>
                  Cantidad de series
                </Typography>
                <TextField
                  onKeyDown={(e) => preventEKey(e)}
                  onPaste={(e) => {
                    isNaN(e.clipboardData.getData('text')) && e.preventDefault()
                  }}
                  InputProps={{
                    style: textFieldStyles,
                    inputProps: { min: '1' },
                  }}
                  variant='outlined'
                  fullWidth
                  required
                  id='series'
                  name='series'
                  type='number'
                  {...register('series', {
                    required: true,
                  })}
                  error={!!errors.series}
                />
                <Typography
                  variant='inherit'
                  color='red'
                  sx={{ paddingTop: 1 }}
                >
                  {errors.series?.type === 'observaciones' &&
                    'La cantidad es obligatoria'}
                </Typography>
              </div>
              <div className={classes.halfRow} >
                <Typography className={classes.tagName} style={{fontSize: '14px'}}>
                  Cantidad de caravanas:
                </Typography>
                <Typography className={classes.tagName} style={{textAlign: 'center', fontSize: '20px', marginTop: '15px'}}>
                  {numberWithDots(series * numberTo)}u
                </Typography>
              </div>
            </div>
            {/* <Typography className={classes.tagName}>
              Observaciones <span className={classes.italic}>(Opcional)</span>
            </Typography>
            <TextField
              InputProps={{ style: textFieldStyles }}
              variant='outlined'
              fullWidth
              id='observaciones'
              placeholder='Ej: Retira Manuel Garcia, de Transportix'
              name='observaciones'
              {...register('observaciones')}
            /> */}
            <Typography className={classes.tagName}>Observaciones</Typography>
            <TextField
              variant='outlined'
              fullWidth
              multiline
              minRows={4}
              maxRows={8}
              id='observaciones'
              placeholder='Ej: Retira Manuel Garcia, de Transportix'
              name='observaciones'
              className={classes.textField}
              {...register('observaciones')}
              helperText={errors?.observaciones?.message ?? ''}
            />
          </form>
        </div>
        <div className={classes.buttons}>
          <Button
            onClick={handleSubmit(addQuantityAndSubmit)}
            variant='contained'
            fullWidth
            className={classes.buttonBuy}
          >
            Continuar
          </Button>

          <Button
            onClick={onClose}
            variant='text'
            fullWidth
            className={classes.buttonBack}
          >
            Volver
          </Button>
        </div>
      </div>
    </Dialog>
  )
}

export default CaravanasNoOficiales
