import React from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { useStylesProducts } from './styles'

const defaultColors = [
  '#E78531',
  '#C53A32',
  '#9FC988',
  '#33B3D1',
  '#FFD21E',
  '#9A4ADA',
]

const coloresMap = {
  "Amarillo": "#FFFF00",
  "Verde": "#008000",
  "Rojo": "#FF0000",
  "Naranja": "#FFA500",
  "Azul": "#0000FF",
  "Negro": "#000000",
  "Blanco": "#FFFFFF",
  "Plateado": "#C0C0C0",
  "Beige": "#F5F5DC",
  "Verde Negro": "#004d00",
  "Negro Verde": "#004d00",
  "Azul Blanco": "#0000FF",
  "Verde Blanco": "#008000",
  "Celeste": "#B2FFFF",
  "Verde Oscuro": "#006400",
  "Rosa": "#FFC0CB",
  "Violeta": "#8A2BE2",
  "Lila": "#C8A2C8",
  "Gris": "#808080",
  "Fucsia": "#FF00FF"
};


export default function ProductsColorTabs({
  label = '',
  ballSize = '20px',
  labelColor = '#B6B6B6',
  colors = defaultColors,
  onClick = (color) => {}
}) {
  const classes = useStylesProducts()

  return (
    <Box
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {label && (
        <Typography
          paragraph
          classes={{ paragraph: classes?.productOffert }}
          style={{
            fontFamily: 'Inter',
            fontWeight: '400',
            fontSize: ballSize !== '12px' ? '14px' : '10px',
            lineHeight: '15px',
            marginBottom: '1px',
            color: labelColor,
            whiteSpace: 'nowrap',
          }}
        >
          {label}:
        </Typography>
      )}
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'flex-start',
          alignItems: 'center',
          paddingLeft: ballSize !== '25px' ? '5px' : '16px',
          flexWrap: 'wrap'
        }}
      >
        {colors?.map((color, index) => (
          <Typography
            key={index}
            component='span'
            onClick={() => onClick(color)}
            sx={{
              width: color === 'Blanco' ? '19px' : ballSize,
              height: color === 'Blanco' ? '19px' : ballSize,
              borderRadius: '100%',
              backgroundColor: coloresMap[color],
              border: color === 'Blanco' ? '1px solid lightgrey' : '',
              marginInline: index ? '3px' : null,
              marginRight: '5px',
              marginLeft: '5px',
              marginBottom: colors.length > 4 ? '2px' : '',
              cursor:'pointer'
            }}
          />
        ))}
      </Box>
    </Box>
  )
}
