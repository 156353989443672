import { promotionsCartChanges } from 'pages/ProductPage/utils/getProductDiscounts'
import { fixAmount } from 'pages/ProductPage/utils/helpers'
import { getProducts } from 'stores/productStore/services/getProducts'

const getSingleProductPriceWithDiscount = (
  product,
  shouldApplyTax,
  shouldApplyDiscount
) => {
  const { precio, precioDeLista, iva, descuentos } = product

  const listPrice = parseFloat(precioDeLista)

  if (!shouldApplyDiscount && !shouldApplyTax) return listPrice

  if (shouldApplyTax) {
    const taxValue = parseFloat(iva) ? (parseFloat(iva) * listPrice) / 100 : 0
    const priceWithTax = listPrice + taxValue
    const discountValue =
      parseFloat(descuentos) && shouldApplyDiscount
        ? (parseFloat(descuentos) * priceWithTax) / 100
        : 0

    return priceWithTax - discountValue
  }

  return precio
}

const getCartProductsTotal = (
  cart,
  shouldApplyTax = false,
  shouldApplyDiscount = false,
) => {
  const productTotal = cart?.product?.reduce((a, c) => {
    const precio = getSingleProductPriceWithDiscount(
      c,
      shouldApplyTax,
      shouldApplyDiscount,
    )
    return a + precio * c.cantidad
  }, 0)

  const productOfficialTotal = cart?.product_official?.reduce((a, c) => {
    const precio = getSingleProductPriceWithDiscount(
      c,
      shouldApplyTax,
      shouldApplyDiscount
    )
    return a + precio * c.cantidad
  }, 0)

  const productManejoTotal = cart?.product_manejo?.reduce((a, c) => {
    const precio = getSingleProductPriceWithDiscount(
      c,
      shouldApplyTax,
      shouldApplyDiscount
    )
    return a + precio * c.cantidad * parseFloat(c?.series || 1)
  }, 0)

  const productsTotal = fixAmount(
    productTotal + productOfficialTotal + productManejoTotal
  )

  return productsTotal
}

const calculatePercentage = (price, disc) =>
    Number(price) - (Number(price) * Number(disc)) / 100

const getCartProductsPromotionsAmount = async (cart, userInfo) => {
  const { product, product_manejo, product_official, cuponSelected } = cart

  product.sort((a,b) => Number(a.sku) - Number(b.sku))
  product_manejo.sort((a,b) => Number(a.sku) - Number(b.sku))
  product_official.sort((a,b) => Number(a.sku) - Number(b.sku))

  const allProducts = [...product, ...product_manejo, ...product_official]

  const promotionChanges = promotionsCartChanges(allProducts, userInfo?.promociones)
  let bonificaciones = []

  for (const row of promotionChanges) {
    const { skuDeRegalo, promotionName, discountRate, quantity, cupon, cantidad } = row
    const item = allProducts.find(({sku}) => sku === skuDeRegalo)
    if(cupon && cuponSelected !== cupon) continue

    if(cupon && cupon === cuponSelected) {
      bonificaciones.push({
        name: promotionName,
        amount: row.descuento || 0,
        pr: row,
        cupon
      })
      continue
    }

    if(!item && quantity > 0 && allProducts.find(({sku}) => row.applyBannerTo === sku)){
      bonificaciones.push({
        name: promotionName,
        amount: 0,
        pr: row,
        cupon
      })
      continue
    }
    if(item) {
      item.precio = calculatePercentage(item.precioDeLista, item.descuentos || 0)
      const discountAmount = Number((item.precio - calculatePercentage(item.precio, discountRate)).toFixed(2));
      const amount = discountAmount * quantity
      if(amount > 0) {
        bonificaciones.push({
          name: promotionName,
          amount,
          pr: row,
          cupon
        })
      }
    }
  }
  bonificaciones = bonificaciones.reduce((acc, curr) => {
    const currentIndex = acc.findIndex(({name}) => name === curr.name)
    if(currentIndex >= 0){
      acc[currentIndex].amount += curr.amount
    } else {
      acc.push(curr)
    }
    return acc
  },[])

  const promotionsAmount = bonificaciones.reduce((acc, {amount}) => {acc += amount; return acc},0)
  return { bonificaciones, promotionsAmount }
}

export const getCartSummary = async (cart, shouldApplyTax, aliquot = {}, userInfo) => {
  const subtotal = getCartProductsTotal(cart)
  const cartProductsWithTax = getCartProductsTotal(cart, true)
  const cartProductsWithDiscount = getCartProductsTotal(cart, false, true)
  const cartProductsWithTaxAndDiscount = getCartProductsTotal(cart, true, true)
  const { bonificaciones, promotionsAmount } = await getCartProductsPromotionsAmount(cart, userInfo)

  const taxAmount = fixAmount(cartProductsWithTax - subtotal)
  const totalDiscounts =
    (shouldApplyTax ? cartProductsWithTax : subtotal) -
    (shouldApplyTax ? cartProductsWithTaxAndDiscount : cartProductsWithDiscount)

  const cartProductsTotal = (shouldApplyTax
    ? cartProductsWithTaxAndDiscount
    : cartProductsWithDiscount) - promotionsAmount
    
  // cupon
  const getCartCupon = (price) => {
    const cartCupon = cart?.cuponDiscount

    const cuponDisocuntAmount = cartCupon?.descuentoPesos

    const isCartCuponBiggerThanTotal = cuponDisocuntAmount > price
    const cartCuponAmount = isCartCuponBiggerThanTotal
      ? price
      : cartCupon?.descuentoPesos || 0

    const cartCuponPercentage = isCartCuponBiggerThanTotal
      ? '100'
      : cartCuponAmount
      ? Math.ceil((cartCuponAmount * 100) / price)
      : 0

    return {
      code: cartCupon?.codigo,
      amount: cartCuponAmount,
      percentage: cartCuponPercentage || 0,
    }
  }

  const cartCupon = getCartCupon(cartProductsTotal)
  const cartCuponAmountWithoutTax = getCartCupon(cartProductsWithDiscount)
    ?.amount
  const cartCuponAmountWithTax = getCartCupon(
    cartProductsWithTaxAndDiscount
  )?.amount

  const totalWithCupon = cartCupon?.amount
    ? fixAmount(cartProductsTotal - cartCupon.amount)
    : cartProductsTotal

  // aliquot

  const totalWithCuponAndTax = cartCuponAmountWithTax
    ? cartProductsWithTaxAndDiscount - cartCuponAmountWithTax
    : cartProductsWithTaxAndDiscount

  const aliquotMinValue = fixAmount(aliquot?.minValue || 0)
  const aliquotAmount =
    aliquot?.percentage && totalWithCuponAndTax >= aliquotMinValue
      ? fixAmount(
          (parseFloat(aliquot?.percentage) * totalWithCuponAndTax) / 100
        )
      : 0

  const total = shouldApplyTax
    ? fixAmount(totalWithCupon + aliquotAmount)
    : totalWithCupon

  return {
    subtotal,
    taxAmount,
    totalDiscounts: fixAmount(totalDiscounts),
    commercialBonus: 0,
    shipping: 0,
    cupon: cartCupon
      ? {
          code: cartCupon?.code,
          amount: cartCupon?.amount,
          percentage: cartCupon?.percentage.toString(),
        }
      : {},
    aliquot: aliquotAmount, // no varia con respecto a si se aplica o no el iva

    totalWithTaxAndAliquot: fixAmount(totalWithCuponAndTax + aliquotAmount), // // no varia con respecto a si se aplica o no el iva
    totalWithouTaxAndAliquot: fixAmount(
      cartProductsWithDiscount - cartCuponAmountWithoutTax
    ), // no varia con respecto a si se aplica o no el iva
    totalWithoutAliquot: totalWithCupon,
    total, // el q paga el usuario
    bonificaciones
  }
}
