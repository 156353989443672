import { forwardRef } from 'react'
import { Dialog, Slide, Box } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import NewClientPopupBackground from '../../assets/newClientPopup3.png'

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const whatsappChatUrl = 'https://api.whatsapp.com/send?phone=5493534250771'

export default function NewClientPopup2({ isOpen = false, onClose }) {
  return (
    <Dialog open={isOpen} onClose={() => {}} TransitionComponent={Transition}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          overflow: 'hidden',
          maxWidth: 400,
        }}
      >
        <div style={{ position: 'absolute', top: 10, right: 10 }}>
          <CloseIcon
            sx={{ color: 'white', cursor: 'pointer' }}
            onClick={onClose}
          />
        </div>
        <img src={NewClientPopupBackground} style={{ width: '100%' }} />
        <Box
          onClick={() => window.open(whatsappChatUrl, '_blank')}
          sx={{
            position: 'absolute',
            cursor: 'pointer',
            height: '40px',
            width: '340px',
            bottom: '85px',
            left: 0,
            right: 0,
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        />
      </div>
    </Dialog>
  )
}
