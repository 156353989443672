import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  Typography,
  Button,
  TextField,
  Dialog,
  useMediaQuery,
  Slide,
  CircularProgress,
  Grid,
  Switch,
  Box
} from '@mui/material'
import { useForm } from 'react-hook-form'
import { useStyles } from './CaravanasOficiales.styles'
import { Link, useNavigate } from 'react-router-dom'
import ClientDetails from './ClientDetails'
import getProducerList from './services/getProducerList'
import AddProducer from './forms/AddProducer'
import { debounce } from 'utils/debounce'
import EditProducerCuit from 'components/EditProducerCuit/EditProducerCuit'
import updateProducer from './services/updateCuitProducer'
import { setSnackbar } from 'stores/snackbarStore'
import { useDispatch } from 'react-redux'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

let tempData = {}

function CaravanasOficiales({ open, onClose, onSave, numberTo, packQuantity }) {
  const desktop = useMediaQuery((theme) => theme.breakpoints.up('sm'))
  const classes = useStyles()
  const minimumQueryLength = 3
  const navigate = useNavigate()
  const dispatch = useDispatch()

  // STATE
  const [loading, setLoading] = useState(false)
  const [selectedProducer, setSelectedProducer] = useState(null)
  const originalCV = useRef(null)
  const [producerOptions, setProducerOptions] = useState([])
  const [showAddProducer, setShowAddProducer] = useState(false)
  const [showAddCuitProducer, setShowAddCuitProducer] = useState(false)
  const [selectedProducerErrors, setSelectedProducerErrors] = useState({})

  const handleSearchProducers = (event) => {
    const query = event.target.value

    if (query.trim().length === 0) {
      setProducerOptions([])
    }
    if (query.length >= minimumQueryLength) {
      setLoading(true)
      getProducerList(query)
        .then((res) => {
          if(res.length === 1) {
            setSelectedProducer(res[0])
            setProducerOptions([])
            originalCV.current = res[0].digitoVerificador
          }
          else {
            setProducerOptions(res.slice(0, 5))
            setSelectedProducer(null)
          }
        })
        .catch((error) => alert('Error al traer productores', error.message))
        .finally(() => setLoading(false))
    }
  }

  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    setValue,
    reset: resetForm
  } = useForm({
    defaultValues: {
      titular: '',
      productor: '',
      CV: '',
      numeracionDesde: '1',
    },
  })

  const flipShowAddProducer = (e) => {
    setShowAddProducer(!showAddProducer)
  }

  const handleProducerSelect = (producer) => (e) => {
    e.preventDefault()
    setSelectedProducer(producer)
    originalCV.current = producer.digitoVerificador
    setProducerOptions([])
  }

  const addQuantityAndSubmit = async (data, cuitAdded) => {
    if (selectedProducer === null) {
      return alert('Por favor seleccione un productor')
    } 
    if(!selectedProducer.digitoVerificador) {
      return setSelectedProducerErrors({
        ...selectedProducerErrors, 
        digitoVerificador: { message: 'CV es requerido' }
      })
    }
    if(originalCV.current !== selectedProducer.digitoVerificador) {
      await updateProducer(selectedProducer)
    }

    if(!Number(selectedProducer.cuit) && cuitAdded !== true) {
      tempData = data
      return setShowAddCuitProducer(true)
    }
    
    const newData = {
      ...data,
      cantidad: numberTo,
      productor: selectedProducer?.id,
      numeracionHasta: numberTo,
      prioridad: data.prioridad ? 'ALTA' : 'BAJA'
    }
    onSave(newData)
    navigate('/carrito')
    onClose()
  }

  const handleKeyDown = (e) => {
    if(loading) return;

    if(e.key === 'Enter')
      handleSearchProducers(e)
  }

  const prioridad = watch('prioridad')
  const titular = watch('titular')

  const isSeller = useMemo(() => localStorage.getItem('permisos') === '2', [])


  useEffect(() => {
    if(!titular) {
      setProducerOptions([])
    }
  }, [titular])

  useEffect(() => {
    if(!open){
      setProducerOptions([])
      setSelectedProducer(null)
      resetForm()
    }
  }, [open])

  const editProducerAndSubmit = async (cuit) => {
    try {
      await updateProducer({...selectedProducer, cuit})
      addQuantityAndSubmit(tempData, true)
    } catch (error) {
      console.log({error})
      dispatch(
        setSnackbar(true, 'error', 'Error al cargar cuit. Intente nuevamente.')
      )
    }
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      fullScreen={!desktop}
      onClose={onClose}
      maxWidth={'md'}
    >
      <div className={classes.container}>
        <div className={classes.titleAndFormContainer}>
          <Typography className={classes.title}>
            Pedido de caravanas oficiales
          </Typography>

          <form
            style={{ width: '100%', marginBottom: '30px' }}
            onSubmit={(e) => {
              e.preventDefault()
            }}
          >
            <Typography className={classes.tagName}>
              Titular / RENSPA / CUIG
            </Typography>
            <TextField
              InputProps={{
                onKeyDown: handleKeyDown,
                onChange: debounce(handleSearchProducers)
              }}
              variant='outlined'
              fullWidth
              required
              id='titular'
              placeholder='Buscar'
              name='titular'
              className={classes.textField}
              {...register('titular', {
                required: 'El titular es obligatorio',
              })}
              helperText={errors?.titular?.message ?? ''}
            />

            {loading ? (
              <Grid sx={{ textAlign: 'center', padding: '10px' }}>
                <CircularProgress disableShrink size={30} />
              </Grid>
            ) : (
              <>
                {producerOptions.length > 1 && (
                  <>
                    <Typography variant='h3' sx={{ padding: '14px 0px' }}>
                      Seleccione una opción
                    </Typography>
                    {producerOptions.map((producer, index) => (
                      <Button
                        key={index}
                        variant='secondary'
                        sx={{
                          fontSize: '16px',
                          textTransform: 'capitalize',
                        }}
                        onClick={handleProducerSelect(producer)}
                      >
                        {producer.nombre} - {producer.cuig}
                      </Button>
                    ))}
                  </>
                )}
                {selectedProducer ? (
                  <ClientDetails 
                    selectedProducer={selectedProducer} 
                    setSelectedProducer={setSelectedProducer} 
                    errors={selectedProducerErrors}
                    setErrors={setSelectedProducerErrors}
                  />
                ) : showAddProducer ? (
                  <Typography
                    className={classes.agregar}
                    onClick={() => setShowAddProducer(false)}
                  >
                    Volver
                  </Typography>
                ) : (
                  <Typography
                    className={classes.agregar}
                    onClick={() => setShowAddProducer(true)}
                  >
                    Agregar nuevo productor
                  </Typography>
                )}
              </>
            )}
            <Dialog 
              open={showAddProducer}
              TransitionComponent={Transition}
              fullScreen={!desktop}
              onClose={() => setShowAddProducer(false)}
              maxWidth={'md'}
            >
              <AddProducer 
                hideForm={flipShowAddProducer} 
                setProducer={(producer) => {
                  setSelectedProducer(producer)
                  setProducerOptions([])
                }}
              />
            </Dialog>
            <EditProducerCuit 
              isOpen={showAddCuitProducer}
              close={() => setShowAddCuitProducer(false)}
              submit={(cuit) => {
                editProducerAndSubmit(cuit)
              }}
            />
            
            {/* <Grid container sx={{ gap: '20px' }} wrap='nowrap'>
              <Grid sx={{ width: '100%', marginBottom: '20px' }}>
                <Typography className={classes.tagName}>CV</Typography>
                <TextField
                  onKeyDown={(e) =>
                    e?.code?.includes('Key') &&
                    !e?.ctrlKey &&
                    e.preventDefault()
                  }
                  onPaste={(e) => {
                    isNaN(e.clipboardData.getData('text')) && e.preventDefault()
                  }}
                  InputProps={{
                    inputProps: { min: '1' },
                  }}
                  className={classes.textField}
                  variant='outlined'
                  fullWidth
                  required
                  id='productorCV'
                  placeholder='Ej: 203'
                  name='productorCV'
                  type='number'
                  {...register('productorCV', { required: 'el CV es obligatorio' })}
                  helperText={errors?.productorCV?.message ?? ''}
                />
              </Grid>
            </Grid> */}
            {isSeller && (
              <Grid sx={{ width: '100%' }}>
                <Typography className={classes.tagName}>Prioridad</Typography>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                  <Typography>Baja</Typography>
                  <Switch
                    {...register('prioridad')}
                    color="primary"
                    onChange={(e) => {
                      setValue("prioridad", e.target.checked);
                    }}
                    checked={prioridad}
                  />
                  <Typography>Alta</Typography>
                </Box>
              </Grid>
            )}
          </form>
        </div>
        <div className={classes.buttons}>
          <Button
            onClick={handleSubmit(addQuantityAndSubmit)}
            variant='contained'
            fullWidth
            disabled={!selectedProducer}
            className={classes.buttonBuy}
          >
            Continuar
          </Button>

          <Button
            onClick={onClose}
            variant='text'
            fullWidth
            className={classes.buttonBack}
          >
            Volver
          </Button>
        </div>
      </div>
    </Dialog>
  )
}

export default CaravanasOficiales
