import React from 'react'
import { useSelector } from 'react-redux'
import { useStyles } from './ProductPage.styles'
import {
  Grid,
  Box,
  useMediaQuery,
} from '@mui/material'
import ProductsSection from '../../components/ProductsSection'
import { Helmet } from 'react-helmet'
import { ProductDetailsSection } from './ProductDetailsSection'

const ProductPage = () => {
  const desktop = useMediaQuery((theme) => theme.breakpoints.up('sm'), { noSsr: true })
  const products = useSelector(({ productList }) => productList)
  const { product } = useSelector(({ productDetails }) => productDetails)
  const styles = useStyles()
  const itemsToShow = desktop ? 12 : 6
  
  return (
    <>
      <Helmet>
        <title>
          {`${product?.nombreEC || product?.nombre || ''}`} | Villanueva
        </title>
        <meta
          name='description'
          content={
            product?.descripcionGeneral ||
            product?.descripcionTecnica ||
            product?.nombreEC ||
            product?.nombre ||
            ''
          }
        />
        <meta
          name='keywords'
          content={`villanueva, producto, ${
            product?.nombreEC || product?.nombre || ''
          }, ${product?.keywords || ''}`}
        />
      </Helmet>
      <Grid className={styles.container}>
        <ProductDetailsSection />
        {products?.product?.slice?.(0, itemsToShow)?.length > 0 && (
          <Box sx={{ padding: '50px 0px 67px 10px' }}>
            <ProductsSection
              titleSection='Quienes vieron este producto también compraron'
              toURL='/'
              scrollVertical={false}
              data={products.product?.slice(0, itemsToShow)}
              carouselIndex={1}
            />
            <Box sx={{ marginTop: '50px' }} />
            <ProductsSection
              titleSection='Tambien pueden interesarte'
              toURL='/'
              scrollVertical={false}
              buttonLabel='Recomendados'
              data={products.product?.slice(0, itemsToShow)}
              carouselIndex={2}
            />
          </Box>
        )}
      </Grid>
    </>
  )
}

export default ProductPage
