import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'

export default function TableComponent({ rows }) {
  return (
    <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
      <Table sx={{ minWidth: '100%' }} aria-label='simple table'>
        <TableBody>
          {rows?.map((row, index) => (
            <TableRow key={index}>
              <TableCell
                style={{
                  borderSizing: 'border-box',
                  border: '1px solid #CDCDCD',
                  borderTopLeftRadius: index === 0 ? '1px' : null,
                  borderBottomLeftRadius:
                    index === rows.length - 1 ? '1px' : null,
                  padding: '13px 17px',
                  backgroundColor:
                    index % 2 === 0 || index === 0 ? '#EBEBEB' : '#F8F8F8',
                  fontWeight: 700,
                  color: '#444444',
                }}
              >
                {row?.nombre}
              </TableCell>
              <TableCell
                style={{
                  borderSizing: 'border-box',
                  border: '1px solid #CDCDCD',
                  borderTopRightRadius: index === 0 ? '1px' : null,
                  borderBottomRightRadius:
                    index === rows.length - 1 ? '1px' : null,
                  padding: '13px 17px',
                  backgroundColor:
                    index % 2 === 0 || index === 0 ? '#EBEBEB' : '#F8F8F8',
                  color: '#444444',
                }}
              >
                {row?.valor}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
