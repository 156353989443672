import React, { useEffect, useState } from 'react'
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  Button,
  Dialog,
  Slide,
  useMediaQuery,
} from '@mui/material'
import useStyles from './ConsultarProducto.styles'
import CloseIcon from '@mui/icons-material/Close'
import { useForm } from 'react-hook-form'
import { emailRegex, cuitRegex } from '../../utils/regesx'
import getSucursales from './services/getSucursales'
import postConsulta from './services/postConsulta'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

function ConsultarProducto({
  open,
  onClose,
  productName = 'Bota Calfor Pampeana Negra - N°40',
}) {
  const [sucursales, setSucursales] = useState([])
  const [sucursalSeleccionada, setSucursalSeleccionada] = useState('BB')

  const classes = useStyles()
  const desktop = useMediaQuery((theme) => theme.breakpoints.up('sm'))
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      fullName: null,
      email: null,
      cuit: null,
      sucursal: null,
      mensaje: null,
    },
  })

  useEffect(() => {
    getSucursales().then((res) => setSucursales(res))
  }, [])

  const handleFormSubmit = (data) => {
    const sucursal = sucursales.find((el) => el.codigo === sucursalSeleccionada)
    const body = { ...data, sucursal: sucursal?.nombre || sucursalSeleccionada }
    postConsulta(body)
      .then(() => {
        onClose()
        alert(
          'Consulta enviada, estaremos comunicandonos con usted a la brevedad!'
        )
      })
      .catch((error) => {
        console.log(error)
        alert('Error al enviar consulta, intente nuevamente')
      })
  }

  const handleSelect = (e) => {
    setSucursalSeleccionada(e.target.value)
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      fullScreen={!desktop}
      onClose={onClose}
      maxWidth={'sm'}
    >
      <Grid className={classes.container}>
        <CloseIcon className={classes.closeIcon} onClick={onClose} />
        <Grid className={classes.content}>
          <Typography variant='h2' className={classes.title}>
            ¿En qué podemos ayudarte?
          </Typography>
          <Typography className={classes.description}>
            Completá este formulario, y a la brevedad nos estaremos contactando.
          </Typography>

          <form onSubmit={(e) => e.preventDefault()}>
            <Typography className={classes.formLabel}>
              Nombre y Apellido
            </Typography>
            <TextField
              variant='outlined'
              fullWidth
              required
              id='fullName'
              name='fullName'
              placeholder='Ingrese nombre y apellido'
              {...register('fullName', {
                required: 'El nombre y apellido es obligatorio',
              })}
              helperText={errors?.fullName?.message ?? ''}
              inputProps={{ className: classes.textFieldInput }}
              className={classes.textField}
            />

            <Typography className={classes.formLabel}>Email</Typography>
            <TextField
              variant='outlined'
              type='email'
              fullWidth
              required
              id='email'
              name='email'
              placeholder='Ingrese email'
              {...register('email', {
                required: 'El email es obligatorio',
                pattern: {
                  value: emailRegex,
                  message: 'Formato de email inválido',
                },
              })}
              helperText={errors?.email?.message ?? ''}
              inputProps={{ className: classes.textFieldInput }}
              className={classes.textField}
            />

            <Typography className={classes.formLabel}>Cuit</Typography>
            <TextField
              variant='outlined'
              fullWidth
              required
              id='cuit'
              name='cuit'
              placeholder='Ej: 30-12322878-4'
              {...register('cuit', {
                required: 'El cuit es obligatorio',
                pattern: {
                  value: cuitRegex,
                  message: 'Formato de cuit inválido',
                },
              })}
              helperText={errors?.cuit?.message ?? ''}
              inputProps={{ className: classes.textFieldInput }}
              className={`${classes.textField} ${classes.shorterTextField}`}
            />

            <Typography className={classes.formLabel}>
              Sucursal más cercana
            </Typography>

            <Select
              inputProps={{
                className: classes.textFieldInput,
              }}
              className={`${classes.textField} ${classes.shorterTextField}`}
              value={sucursalSeleccionada}
              onChange={handleSelect}
            >
              {sucursales.map((sucursal, index) => (
                <MenuItem value={sucursal?.codigo} key={index}>
                  {sucursal?.nombre}
                </MenuItem>
              ))}
            </Select>

            <Typography className={classes.formLabel}>Mensaje</Typography>
            <TextField
              variant='outlined'
              fullWidth
              required
              multiline
              minRows={4}
              id='mensaje'
              name='mensaje'
              placeholder={`Quiero realizar una consulta por el producto ${productName} ...`}
              {...register('mensaje', {
                required: 'El mensaje es obligatorio',
              })}
              helperText={errors?.mensaje?.message ?? ''}
              inputProps={{ className: classes.textMultilineInput }}
              className={classes.textField}
            />
          </form>
          <Button
            variant='primary'
            className={classes.actionButton}
            onClick={handleSubmit(handleFormSubmit)}
          >
            Consultar
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default ConsultarProducto
