import React, { useEffect, useMemo } from 'react'
import { Route, Routes } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import { useScrollToTop } from 'customHooks/useScrollToTop'
import theme from '../src/theme/default'
import Home from './pages/Home/Home'
import CartPage from './pages/CartPage/CartPage'
import PaymentMethodPage from './pages/PaymentMethodPage/PaymentMethodPage'
import PlaceOrderPage from './pages/PlaceOrderPage/PlaceOrderPage'
import ResumenPage from './pages/ResumePage/ResumenPage'
import RegisterPage from './pages/RegisterPage/RegisterPage'
import OrderListPage from './pages/OrderListPage/OrderListPage'
import ProductPage from './pages/ProductPage/ProductPage'
import SucursalesPage from './pages/SucursalesPage/SucursalesPage'
import LayoutComponent from './components/LayoutComponent'
import ResetPassword from 'pages/ResetPassword/ResetPassword'
import ResetPasswordSent from 'pages/ResetPassword/ResetPasswordSent'
import ChangesAndReturnsSent from 'pages/ChangesAndReturns/ChangesAndReturnsSent'
import RegisterPageSent from 'pages/RegisterPage/RegisterPageSent'
import NotFoundPage from 'pages/NotFoundPage/NotFoundPage'
import NewsPage from 'pages/NewsPage/NewsPage'
import ProtectedRoutes from 'ProtectedRoutes'
import CuentaCorriente from 'pages/CuentaCorrientePage/CuentaCorriente'
import CuentaCorrientePaymentPage from 'pages/CuentaCorrientePaymentPage/CuentaCorrientePaymentPage'
import SuccessPage from 'pages/CuentaCorrientePaymentPage/SuccessPage/SuccessPage'
import ChangesAndReturns from 'pages/ChangesAndReturns/ChangesAndReturns'
import WorkWithUs from 'pages/WorkWithUs/WorkWithUs'
import DownloadFiles from 'pages/DownloadFiles/DownloadFiles'
import CataloguePage from 'pages/CatalogPage - refactor/CatalogPage'
import { useDispatch, useSelector } from 'react-redux'
import { getInitialData } from 'stores/appStore/actionsApp'
import SiteInMaintenancePopUp from 'components/SiteInMaintenancePopup/SiteInMaintencePopup2'
import CompraRapidaModal from 'components/CompraRapida/CompraRapida'
import PagePreview from 'pages/PagePreview/PagePreview'
import AboutUsPage from 'pages/AboutUs/AboutUsPage'

function App() {
  const { appInfo } = useSelector((state) => state.app)
  const isSigned = useSelector((state) => state?.userAuth?.signedIn)
  const userPermision =
    useMemo(() => localStorage.getItem('permisos'), [
      appInfo?.enabled,
      isSigned,
    ]) || useSelector((state) => state?.userAuth?.userInfo?.permisos)
  const dispatch = useDispatch()
  useScrollToTop()

  useEffect(() => {
    dispatch(getInitialData())
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <LayoutComponent>
        <SiteInMaintenancePopUp
          isOpen={
            // !!isSigned &&
            appInfo?.enabled === false 
            // &&
            // !!userPermision &&
            // userPermision !== '2'
          }
        />
        <CompraRapidaModal />
        <Routes>
          {/* Publicas */}
          <Route path='/producto/:sku' element={<ProductPage />} />
          <Route path='/registro' element={<RegisterPage />} />
          <Route path='/recuperar-acceso' element={<ResetPassword />} />
          <Route
            path='/recuperar-acceso/enviado'
            element={<ResetPasswordSent />}
          />
          <Route path='/registro/enviado' element={<RegisterPageSent />} />
          <Route path='/catalogo/*' element={<CataloguePage />} />
          <Route path='/noticias' element={<NewsPage />} />
          <Route path='/noticias/:id' element={<NewsPage />} />
          {/* <Route path='/promociones-vigentes' element={<PromocionesPage />} /> */}
          <Route path='/trabaja-con-nosotros' element={<WorkWithUs />} />
          <Route path='/sucursales' element={<SucursalesPage />} />
          <Route path='/nosotros' element={<AboutUsPage />} />
          <Route path='/' element={<Home />} exact />
          <Route path='*' element={<NotFoundPage />} />

          {/* Privadas */}
          <Route element={<ProtectedRoutes />}>
            <Route path='/carrito' element={<CartPage />} />
            <Route path='/carrito/:id' element={<CartPage />} />
            <Route path='/___pagePreview___/:pageName' element={<PagePreview />} />
            <Route path='/finalizar-compra' element={<PaymentMethodPage />} />
            <Route path='/pedido-realizado' element={<PlaceOrderPage />} />
            <Route path='/resumen' element={<ResumenPage />} />
            <Route path='/mis-pedidos' element={<OrderListPage />} />
            <Route path='/cuenta-corriente' element={<CuentaCorriente />} />
            <Route path='/archivos' element={<DownloadFiles />} />
            <Route
              path='/cuenta-corriente/pago'
              element={<CuentaCorrientePaymentPage />}
            />
            <Route
              path='/cuenta-corriente/pago/enviado'
              element={<SuccessPage />}
            />
            {/* <Route path='/promociones-vigentes' element={<SuccessPage />} /> */}
            <Route path='/devoluciones' element={<ChangesAndReturns />} />
            <Route
              path='/devoluciones/enviado'
              element={<ChangesAndReturnsSent />}
            />
          </Route>

          {/* No se estan usando */}
          {/* <Route path='/allCategories' element={<AllCategoriesPage />} /> */}
          {/* <Route path='/shipping' element={<ShippingPage />} /> */}
        </Routes>
      </LayoutComponent>
    </ThemeProvider>
  )
}

export default App
